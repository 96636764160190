@import url('https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Klee+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oxygen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&display=swap');

@font-face {
  font-family: 'San Francisco';
  src: url('../FontsFree-Net-SFProDisplay-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'San Francisco Bold';
  src: url('../FontsFree-Net-SFProDisplay-Regular.ttf') format('truetype');
}

body {
  background-color: rgba(255, 254, 254, 1);
}
p {
  font-family: 'San Francisco', Arial, sans-serif;
}


h3 {
  font-weight: 800;
  font-family: 'San Francisco Bold', Arial, sans-serif;
}

b {
  font-family: 'San Francisco Bold', Arial, sans-serif;
}


.historySection {
  margin-bottom: 50px;
  margin-left: -20px;
}

.historySection p {
  font-size: 19px;
}

.historySection h3 {
  margin-bottom: 15px;
}

.icons h3 {
  margin-top: 40px;
  font-size: 23px;
}

.icons p {
  font-weight: 350;
  font-size: 20px;
  line-height: 1.381002381;
}

.poster {
    width: 100%;
    margin-top: 0px;
    margin-left:auto;
    margin-right:auto;
    display: block;
    background-color: #d0c6eb;
    padding-bottom: 50px;
    padding-top: 50px;
    height:100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    
}




.des {
  margin-top:10px;
  margin-bottom: 30px;
  font-size: 18.5px;
}


.logo {
  height: 50px;
  margin-left: 0px;
  margin-right: 13px;
  margin-bottom: -25px;
}


.mainLogo {
  height: 50px;
  filter: invert(100%);
  margin-right: 10px;
}


.header {
  text-align: center;
  font-family: 'Rowdies', cursive;
  display: block;
  margin-bottom: 15px;
  color:rgb(65, 64, 73) !important;
}

.header-text {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}


.des {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 70%;
  color: var(--bs-body-color) !important;
}

.sipdug {
  margin-top:0px;
  margin-bottom: 10px;
  width: 400px;
  height: 300px;
  margin-left: auto;
  margin-right:auto;
  display: block;
}

.links {
  text-align: center;
  display: block;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0;
}

.links button {
  display: inline-block;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 300px;
  margin: 5px 5px;
text-align: center;
}

.links {
  margin-top:10px;
}

.link-text {
  margin:0px;
  padding:0px;
}

.contact-des {
  margin-top: -20px;
  margin-bottom: -10px;
  font-size: 21px;
}

.lil-logo {
  width: 30px;
  height: auto;
  padding-right: 10px;  
  padding-bottom:5px;
  filter: invert(100%);
}



@media only screen and (max-width: 1000px) {
  
}

.navbar {
  padding-left: 20px;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%; 
  overflow: hidden;
  margin-bottom: 30px;
}

.navbar-nav {

  margin-right: auto;
}






#mission-card {
  margin-top:0px;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width:1070px) {
  #mission-card {
    width: 95%;
  }

  .area1 {
    margin-bottom: 750px !important;
  }
}

.eboards {
text-align: center;
width: 90%;
padding-left: 5px;
margin-left: auto;
margin-right: auto;
}

#eboard {
  margin:30px;
  display: inline-block;
  width: 280px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.sms{
  text-align: center;
  margin-top: 7px;
}

.sms div{
  display: inline-block;
  width: 35px;
  margin:3px;
}

.sms img {
  width: 30px;
  filter: invert(30%);
}

.title{
  text-align: center;
  margin-top: 60px;
  margin-bottom: 30px;
  font-size: 35px;
}


.whyList {
  padding-top: 30px;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.whyList ul {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.whyList li {
  font-size: 19.5px;
  margin-bottom: 5px;
  width: 100%;
}

.whyList h3 {
  font-size: 21.5px;
}

@media only screen and (max-width:1000px) {
  .whyList {

    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -30px;
  }

  .icons {
    margin-bottom: -120px !important;
  }


  .icons img {
    width: 40px;
    height: 40px;
  }
  
  .icons p {
    font-size: 20px;
    width: 100%;
  }

  .icons h3 {
    margin-top: 5px;
  }

  .icons div {
    width: 100%;
  } 


  .links button{
    width: 300px;
    height: 45px;
    padding: 5px;
    font-size: 17px;
  }

  .links img {
    width:0px;
    display: none;
  }

  .title {
    margin-top: 130px;
  }

  .logo {
    margin-bottom: 5px !important;
  }
}


.card-img-top {
  width: 100%;
  height: 208px;
  object-fit: cover; /* Maintain the image aspect ratio and cover the container */
  border-radius: 3px;
}


.card-text {
  font-size: 19.5px;
  margin-top:0px;
  margin-bottom: 10px;
  font-weight: 350;
}

.dog {
  margin-top:-7px;
  width: 55px;
  height: 55px;
}

iframe {
  border: 1px solid rgb(177, 156, 196);
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}



.gallery {
  margin-top:-60px;
  height:100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
}

.d-block {
  width: 500px;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.carousel-control-prev-icon, .carousel-control-next-icon  {
  background-color: #444450;
}
#carouselExampleControls {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.sr-only {
  color:black;
}

.icons {
  margin-top: 50px;
  margin-bottom: -40px;
  text-align: center;
}

.icons div {
  display: inline-block;
  width: 250px;
  margin: 50px;
}

.contact {
  margin-top:10px;
  text-align: center;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  border-radius: 5px;
  padding-bottom: 40px;
}

.contact2 img{
  width: 130px;
  height: 115px;


}

.contact2 {
  border-right: 0.5px solid black;
  height: 150px;
  width: 300px;
}

.contact3 {
  width: 300px;
  margin-top:20px;
}

.contact3 p {
  text-align: left;
  margin-left: 80px;
  margin-right: auto;
  font-size: 19px;
  font-weight: 350;
}

.contact3 img {
  width: 40px;
  padding: 10px;
}

.gang {
  margin-top: 50px;
  display: flex;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.sponsor {
  text-align: center;
  margin-bottom: 30px;
}

.sponsor p, .sponsor ul {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.sponsor p {
  font-size: 21px;
}

.sponsor ul {
  font-size: 18px;
}

.sponsorList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; 
}

.sponsor {
}

.sponsorList p {
  width: 300px !important;
}


.sponsor {
  text-align: center; /* Center-align the content */
}

.sponsorPic {

}

.sponsorText {
  text-align: center; /* Center-align the text */
}

#name {
  font-size: 22px;
  font-weight: 800;
  font-family: 'San Francisco Bold', Arial, sans-serif;
}

#position {
  font-weight: 600;
  font-size: 16px !important;
}

#position, #major {
  font-size: smaller;
  margin-bottom: 0px;
}

#major {
  font-size: 16px !important;
}


@media only screen and (max-width: 680px) {
  .logo {
    margin-bottom: 5px !important;
  }
  .sponsor p{
    width: 95%;
  }

  .contact {
    width: 520px;
  }

  .gang {
    width: 520px;
  }

  .contact-des {
    width: 500px;
  }

  .contact2 {
    width: 50%;
  }

  .contact3 p {
    font-size: 17px;
  }

  .contact3 img {
    width: 33px;
    height: 33px;
  }

  .contact3 {
    width: 50%;

  }

  .contact3 p {
    margin-left: 20px;
  }

  .sponsor ul {
    width: 90%;
  }

}

.event img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 500px;
  height: 500px;
}

@media only screen and (max-width: 500px) {
  .event img {
    width: 100%;
    height: 100%;
  }

  .d-block {
    width: 320px;
    height: 320px;
  }

  .contact {
    width: 330px;
  }

  .gang {
    width: 330px;
  }

  .contact-des {
    width: 330px;
  }

  .contact3 img {
    visibility: hidden;
  }

  .contact p {
    margin-bottom: -10px;
  }
}

.card-title {
  font-size: 21.5px;
}

.title, p, img, .card-title, h3, h2 {
  overflow-y:hidden;
}


* {
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

.context {
    width: 100%;
    position: absolute;
    top:100px;
}

.context h1{
    text-align: center;
    color: #fff;
    font-size: 50px;
}


.area1{
    background: #ffffff;  
    width: 100%;
    margin-bottom:700px;
}

.area2 {
  visibility: hidden;
}


@media only screen and (max-width: 885px) {

  .context h1 {
  font-size: 30px !important;
  }
  .context p {
    font-size: 18px;
  }

 
  .pic img {
    width: 250px;
    height: 250px;
  }


  .area1 {
    margin-bottom: 700px !important;
  }

}


@media only screen and (max-width: 515px) {
  .context h1 {
    font-size: 25px !important;
  }
}

@media only screen and (max-width: 421px) {
  .context h1 {
    font-size: 20px !important;
  }

  .mission {
    margin-top: 100px !important;
  }
}


.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(38, 32, 90, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}



.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}


.proj ul{
  display: block;
  text-align: center;
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0;
}


.proj img {
  width: 350px;
  height: 350px;
  border-radius: 5px;
}

.proj a {
  text-decoration: none;
}

.proj .link {
  height:auto;
  margin-left: auto;
  margin-right: auto;
  display:block;
}

.proj li {
  font-size: 15px;
  display: inline-block;
  padding: 5px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;

}

.projDes {
  padding: 10px;
}
.projHeader div {
  width: 100%;
  display: inline-block;
}

.projDesDes {
  margin-top:-15px;
  font-size: 20px;
  width: 550px;
  float: left;
  text-align: left;
  
}


@media only screen and (max-width: 600px) {
  .projDesDes {
    width: 100%;
  }

  .proj img {
    width:  350px;
    height: 350px;
  }


}


.projDes a {
  border-radius: 10000rem;
  padding: 9px 16px;
  border: 2.5px solid blue;
  font-size: 1rem;
  margin-left: -5px;
  margin-bottom: 100px;
  float: left;
}



.tools  {
  text-align: center;
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  padding-top:6px;
  padding-bottom:6px;
  margin-top:0px;
  margin-bottom: 3px;
  background-color: #babacf;
  border-radius: 3px;
  color:rgb(0, 0, 0);
}


.img__description {
  width: 290px;
  height: 290px;

  position: absolute;
  margin-left:5px;
  margin-top: 5px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(247, 247, 247, 0.877);
  color: black;
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s, visibility .2s;
  text-align: left;
  font-size: 8.5px;
}

.img__wrap:hover .img__description {
  visibility: visible;
  opacity: 1;
}


.img__description img {
  width: 15px;
  height: 15px;
}

.img__description .link {
  width: 30px;
  height: 30px;
}


.roles p {
  font-size: 13px;
  float:left;
}

.roles {
  margin-bottom: -15px;
}

h2 {
  text-align: left;
  margin-top:30px;
  margin-left: 0px;
  font-weight: 700;
  font-size: 25px;
}

.history {
  margin-top:90px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  display: block;
  text-align: center;
}

.sponsorPic {
  width: 180px;
}

.rank {

    font-size: 21px;
}

.rank div {
  display: inline-block;
  margin-left: 90px;
  margin-right: 90px;
}

#diamond {
  color:rgb(38, 117, 117);
  font-weight: bold;
}

#gold {
  color:rgb(169, 176, 36);
  font-weight: bold;
}

#silver {
  color: rgb(143, 143, 160);
  font-weight: bold;
}

#tlo {
  color:rgb(169, 176, 36);
  text-align: center;
  font-weight: bold;
}